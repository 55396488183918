import React, { useState, useEffect, Children, useRef } from 'react';
import { Link } from 'react-router-dom';
import {  Statistics,button1, Airplane, NavBar } from '../../components' 
import './home.css'
import halifax from '../../images/UnitedTravels_high_res.png'
import service from '../../images/Service_high_res.png'
import legendary from '../../images/Legendary_high_res.png'
import statsCard from '../../images/30years.jpeg'
import { motion, AnimatePresence } from 'framer-motion';
import Marquee from "react-fast-marquee";
import { Button } from '@mui/base';
import luft from '../../images/luft.png'
import A3802 from '../../images/A380.png'
import Customers300k from '../../images/300k.jpeg'

import { IconButton } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Logo from '../../images/Logo.png'
import Balloons from "../../images/Ballons.png"
import {Section} from './ScrollTrigger';
import A380 from '../../images/A380.png'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { useInView } from 'react-intersection-observer';
import Facebook  from '../../images/facebook.png';
import Menu from '../../components/brand/Menu';
import { useWindowWidth } from './windowWidth';






const images = [
    {
        url:halifax,
        text:"UNITED TRAVELS",
        body:"30+ years in Halifax, Nova Scotia. A Proud Travel Agency in the Heart of the City",
        style: {
            top: '20%',
            filter: 'brightness(128%)',

          },
        mobileStyle: {
          top: '10%',
          filter: 'brightness(128%)',
          left: '30%'
        }
          
    },
    {
        url:legendary,
        text:"LEGENDARY",
        body:"We have booked 300,000+ trips for our clients. With that kind of experience we understand what our clients need most; a trust partner that they can call throughout their journey with their travel needs.",
        style: {
            top: '32%',
            left:'4%',
            'fontSize':'11rem'

          },
          mobileStyle:{
            top: '32%',
            left:'2%'
          }

    },
    {
        url:service,
        text:"SERVICE",
        body:"We are an award-winning travel agency, that serves our clients through impeccable service. Whether you’re beset by world events or missed your flight, we are here by your side throughout your journey ",
        style: {
            filter: 'brightness(128%)',
            background: 'linear-gradient(#2B2D43 0%, #FFF 100%)'

          },
        mobileStyle:{
          filter: 'brightness(128%)',
          background: 'linear-gradient(#2B2D43 20%, #FFF 100%)'
        }
    }  
  ];

export const Home = () => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [hover, setHover] = React.useState(false);
    const [callNumber, setCallNumber] = useState('Call Us')
    let timer = useRef(null); // define timer using useRef Hook
    const windowWidth = useWindowWidth();


    const { ref, inView } = useInView({
        triggerOnce: false, // Change this to false if you want the animation to trigger again whenever it comes in view
      });

      const variants = {
        hidden: { opacity: 0, x:-1000, y:100, transition: {
            duration: 0.7,
            ease: 'easeIn',
            type: "spring",
          }, }, // Change '100' to the number of pixels you want the element to start from the right
        show: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.7,
            ease: 'easeInOut',
            type: "tween" 
          },
        },
      };
      const TextVariants = {
        hidden: { opacity: 0, x:-1000, transition: {
            duration: 0.7,
            ease: 'linear',
            type: "spring",
          }, }, // Change '100' to the number of pixels you want the element to start from the right
        show: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.7,
            ease: 'easeOut',
            type: "tween" 
          },
        },
      };
      const CallVariants = {
        hidden: { opacity: 0,  transition: {
            duration: 0.7,
            ease: 'easeInOut',
            type: "spring",
          }, }, // Change '100' to the number of pixels you want the element to start from the right
        show: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.7,
            ease: 'easeInOut',
            type: "tween" 
          },
        },
      };


      useEffect(() => {
        timer.current = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 7000);

        return () => {
            clearInterval(timer.current);
        };
    }, []);

    const handleRectangleClick = (index) => {
        setCurrentImageIndex(index);
        clearInterval(timer.current); // clear the existing interval
        timer.current = setInterval(() => { // restart the interval
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 7000);
    };
  
    


   
  return (
        <div className='UnitedTravels1' >
        <head>
        <meta name="description" content="The best travel agency for flights, tours and vacations"></meta>
        </head>
        

        <div className="slideshow">

            <div className="image-slider">


                {images.map((image, index) => (
                <div
                    key={index}
                    className={`slide-image ${index === currentImageIndex ? 'slide-active' : ''}`}
                    style={{ backgroundImage: `url(${image.url})` }}
                ></div>
                ))}
            </div>


    {images.map((image, index) => (
        <div
        key={index}
        className={`slide-content ${index === currentImageIndex ? 'slide-active' : ''}`}
        >
        
        <div className="title-1" style={useWindowWidth <= 768 ? image.mobileStyle : image.style}>{image.text}</div>
        <NavBar/>

        <div className='body'>{image.body}</div>
        <div className='CallUsContainer' onMouseEnter={() => setCallNumber('902-422-0111')} onMouseLeave={() => setCallNumber('Call Us')} > 
        
            <div className='CallUsBox'>
            <a className="my-link" href="tel:902-422-0111">
            <div className='CallUs'>
             {callNumber}
             </div>
             </a>
             </div>
        </div>
        </div>
    ))}

  
    <div className="rectangle-container">
        {images.map((_, index) => (
        <div
            key={index}
            className={`rectangle-3 ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => handleRectangleClick(index)}
            style={{
            backgroundColor: index === currentImageIndex ? 'white' : 'rgba(255, 255, 255, 0.5)',
            }}
        ></div>
        ))}
    </div>
    </div>



      <section className='AirportCodeContainer'>
      <Marquee className='AirportCode' direction='right' autoFill='true'>
        YHZ-YUL-YYZ-YVR-LHR-JFK-DEL-FRA-BKK-NRT-CDG-AMD-DXB-AUS-HKG-BOM-LAX-DEL-AUH-IAH-IST-LOS-
        </Marquee>
        

      </section>
      <div className='OurStoryContainer' >
      <div className='OurStory'>Our Story</div>
      <div className='StoryBlurb'>Today, our focus is legendary service. When United Travels started in the 1990s, that was our ethos. 30+ years later that ethos is what has allowed allowed our company to grow and continue serving our amazing clients. Our client journey begins with a call and doesn’t end until they are home -- that is what makes us special</div>
      <div className='ButtonBox'> 
      <Link to='/about' className='InspirationButton2'>Read More</Link>
        </div>
    <div className='Line8'></div>
    <div className='Line9'></div>
    </div>
    <div className='StatsContainer'>
        <div className='StatsHeader'>Numbers are telling our tale</div>
        <div className='StatsP'>United Travels, an esteemed travel agency in Halifax, NS, has etched a remarkable saga spanning three decades. Their indomitable spirit has seen 300,000 airline tickets sold, each one a ticket to dreams realized. And as the sun sets on countless journeys, tens of thousands of customers find solace in their remarkable service, a testament to United Travels' unwavering commitment. A love of exploration, a passion for service has helped United Travels become the premier agency in Atlantic Canada.</div>

    <div  className="StatsBox" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
       <motion.img className="CardImage"  src={statsCard} alt="Image 1"/>
      <AnimatePresence>
      {hover && (
          <motion.div
            className="Curtain"
            initial={{ height: '0%' }}
            animate={{ height: '100%' }}
            exit={{ height: '0%' }}
            transition={{
              height: { duration: 0.5, type: 'spring', bounce:0.3, stiffness: 75},
            }}
          >
                
            <img className="CardImage" src={Customers300k} alt="Image 2" />
          
          </motion.div>
          )}
      </AnimatePresence>

    </div>
    </div>

        <div className='TravelContainer'>
        <div className='AirplaneContainer'>
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={inView ? 'show' : 'hidden'}
            >
           
       <img className='AirplaneImage' src={A3802} alt="Airplane"/>
               
          </motion.div>
          <motion.div
            ref={ref}
            variants={TextVariants}
            initial="hidden"
            animate={inView ? 'show' : 'hidden'}
            >
            <div className='Tickets'>300k tickets is enough to fill this bad boy 1,764 times!</div>
          </motion.div>
          


        </div>


        <div className='TravelGptContainer'>
          <div className='TravelGptHeader'>WHAT IS TRAVELGPT?</div>
          <div className='TravelGptBody'>
            We integrated a cool AI tool 
            that allows our clients  to create, design and plan 
            a unique travel itinerary around their interests.
          </div>
          <Link to='/travelgpt' className='TravelGptBtnContainer'>
            <div className='TravelGptBtnText'>Try it Out</div>
          </Link>
         

          <div className='LineShort'></div>
          <div className='LineLong'></div>
        </div>
        </div>

    <div className='CallContainer'>
    <Section>
        <div className='CallText'>Give us a call and let’s start your next adventure</div>
        </Section>
        <div className='CallBtnContainer'  onMouseEnter={() => setCallNumber('902-422-0111')} onMouseLeave={() => setCallNumber('Call Us')}>
        <a className="my-link" href="tel:902-422-0111">
            <div className='CallBtnText'>
             {callNumber}
             </div>
             </a>
        </div>
    </div>

    <div className='FooterContainer'>
        <div className='FooterText'>THE GREATEST TIME TO TRAVEL  IS NOW</div>
        <div className='SubscribeToOurNewsle'> subscribe to our newsletter</div>
        <div className="input-container">
        <input className="rounded-input" type="text" placeholder="your@email.com" />
        <button  className='SubscribeBtn'  >
               
          </button>
        </div>

        <div className='FooterNav'>
              <Link to='about' className='AboutFooter'> about </Link>
              <Link to='travelgpt' className='TravelGPTFooter'> travelGPT</Link>
              <Link to='/contact' className='ContactFooter'> contact</Link>

        </div>
        <div className='Address'>5201 DUKE ST HALIFAX, NS B3J 1N9</div>
        <div className='Year'> <CopyrightIcon /> {(new Date().getFullYear())}</div>
        <img className='FooterLogo' src={Logo}></img>
        <Link to='https://www.facebook.com/unitedtravels.ca'>
        <img className='Facebook' src={Facebook}></img></Link>

    </div>


    </div>
    );
}

export default Home