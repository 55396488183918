/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { styled } from '@mui/material/styles';

const Property1Default = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `100px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const YhzYulYyzYvrLhrJfkDe = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0)`,
  fontStyle: `normal`,
  fontFamily: `Font Awesome 5 Free`,
  fontWeight: `400`,
  fontSize: `48px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  WebkitTextStroke: `1px rgba(43, 45, 67, 1)`,
  width: `2591px`,
  height: `100px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

function Ticker(props) {
  return (
    <Property1Default className={props.className}>
      <YhzYulYyzYvrLhrJfkDe>
        {`YHZ-YUL-YYZ-YVR-LHR-JFK-DEL-FRA-BKK-NRT-CDG-YHZ-YUL-YYZ-YVR-LHR-JFK-DEL-FRA-BKK-NRT-CDG`}
      </YhzYulYyzYvrLhrJfkDe>
    </Property1Default>
  );
}

export default Ticker;
