import React, { useState } from 'react'
import './contact.css'
import TextField from '@mui/material/TextField';
import { useRadioGroup } from '@mui/material/RadioGroup';
import RadioBox from './RadioBox'
import SendButton from '../Buttons/SendButton'
import emailjs from 'emailjs-com';


const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedValue, setSelectedValue] = useState("General Inquiry");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_gj2ct1k', 'template_z5h5fap', {
      from_name: firstName + ' ' + lastName,
      to_name: 'United Travels',
      message: message,
      subject: selectedValue,
      email: email,
      phone_number: phoneNumber
    }, '2aZT-vjXAu4wdmidp')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSuccessMessage("Your message has been sent successfully!");
      // Clear the fields
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setSelectedValue("General Inquiry");
      setMessage('');
    }, (error) => {
      console.log('FAILED...', error);
    });
  };


  return (
    <div className='ContactContainer'>
      <div className='ContactScreen'>
        <div className='ContactBox'>
          <div className='ImageBox'></div>
          <TextField
            required
            id="standard-required"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            variant="standard"
            className='NameBox'
          />
          <TextField
            required
            id="standard-required"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            variant="standard"
            className='LastNameBox'
          />
          <TextField
            required
            id="standard-required"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            variant="standard"
            className='EmailBox'
          />
          <TextField
            required
            id="standard-required"
            label="Phone Number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            variant="standard"
            className='PhoneNumber'
          />
                    <div className='Subject'> Select Subject?</div>

          <div className='RadioBox'>
          <RadioBox selectedValue={selectedValue}
            handleRadioChange={handleRadioChange}/>
          </div>
          <TextField
          id="standard-multiline-flexible"
          label="Message"
          multiline
          maxRows={4}
          variant="standard"
          className='MessageBox'
          value={message}
          onChange={e => setMessage(e.target.value)}

        />
        {successMessage && <p className='SuccessMessage'>{successMessage}</p>}

        <div className='Button' >
        <SendButton onClick={sendEmail}/>

        </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
