/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { styled } from '@mui/material/styles';
import A3801Image from '../../images/A380.png';
import A38011Image from '../../images/A380.png';

const Property1Default = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `362px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Frame5 = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `696px`,
  height: `0.1px`,
  left: `0px`,
  top: `20px`,
  overflow: `hidden`,
});

const A3801 = styled('div')({
  backgroundImage: `url(${A3801Image})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `0px 4px 7px rgba(0, 0, 0, 0.4)`,
  width: `722px`,
  height: `503px`,
  position: `absolute`,
  left: `-26px`,
  top: `-111px`,
});

const A38011 = styled('div')({
  backgroundImage: `url(${A38011Image})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `0px 4px 7px rgba(0, 0, 0, 0.4)`,
  width: `722px`,
  height: `503px`,
  position: `absolute`,
  left: `-26px`,
  top: `-111px`,
});

const Frame51 = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  width: `696px`,
  height: `0.1px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

function Airplane(props) {
  return (
    <Property1Default className={props.className}>
      <Frame5>
        <A3801></A3801>
      </Frame5>
     
    </Property1Default>
  );
}

export default Airplane;
