import React from 'react';
import { styled } from '@mui/system';

const StateDefault = styled('div')(({ theme }) => ({
    backgroundColor: `rgba(251, 251, 251, 1)`,
    boxShadow: `0px 21px 42px rgba(0, 0, 0, 0.25)`,
    borderRadius: `20px`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    width: '270px',
    height: `77px`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `11px`,
    boxSizing: `border-box`,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      top: '20%',
      maxWidth:'250px'
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  }));
  

const Inspiration = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(43, 45, 67, 1)`,
  fontStyle: `normal`,
  fontFamily: `Plus Jakarta Sans`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `-0.28px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    width:'85px',
    textAlign:'center'

  },
}));

function SendButton(props) {
  return (
    <StateDefault className={props.className} onClick={props.onClick}>
      <Inspiration>{`Send Message`}</Inspiration>
    </StateDefault>
  );
}

export default SendButton;
