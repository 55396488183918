import logo from './logo.svg';
import React , {useState} from 'react'
import './App.css';
import {Article, Brand, Cta, Feature, LoadingScreen, NavBar, TravelGPT, Statistics, Contact} from './components'
import {Footer, Blog, Possibility, Features, WhatGPT3, Header, Home} from './containers'
import { useSnapshot } from "valtio";
import {state} from './store/state'
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Menu from './components/brand/Menu';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from './images/Logo.png'


function App() {
  const snap = useSnapshot(state);

 

  return (
    <Router>
      <title>Atlantic Canada's #1 Travel Agency</title>

    <Menu/>
        <FloatingWhatsApp phoneNumber='+19024220111' accountName='United Travels' avatar={Logo}/>
    <div className="App">

      <Routes>
          <Route path="/"  element={<Home/>}/>
          <Route path="/about" element={<Brand/>} />
          <Route path="/LoadingScreen" component={<LoadingScreen/>} />
          <Route path="/TravelGPT" element={<TravelGPT/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
    </div>
    </Router>
  );
}

export default App;
