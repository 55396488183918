import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./brand.css";
import planevideo from "../../videos/ddi-transcode.mp4";
import twothousands from '../../videos/2000s.mp4'
import twentytens from '../../videos/2010s.mp4'
import NavBar from "../navbar/NavBar";
import { Link } from "react-router-dom";
import Logo from '../../images/Logo.png'
import Menu from './Menu'


const Brand = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600); 
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 600 && window.innerWidth <= 1024);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 



  const sections = [
    {
      year: "1990s",
      title: "YHZ",
      content: planevideo,
      copy:'We acted as an essential partner for global carriers like KLM and Icelandair in Halifax.',
      backgroundColor: "white",
      color:" #ff720c" // Add a color here
      // Add a color here

    },
    {
      year: "2000s",
      title: "Awards",
      content: twothousands,
      copy:'As the world stood still on 9/11 we assisted thousands of stranded passengers at the Halifax International Airport and we recognized for that effort',
      backgroundColor: " #ff720c",
      color:"white" // Add a color here

    },
    {
      year: "2010s",
      title: "Tours",
      content: twentytens,
      copy:'We have grown through world events, global economic downturns and a online revolution and we are still here because of the unmatched service we provide',
      backgroundColor: "#2B2D43",
      color:" #ff720c" // Add a color here

       // Add a color here

    },
  ];

  return (
    
      <div className="about-page">
        {sections.map((section, index) => (
          <motion.div
            key={index}
            className="section"
            style={{ 
              flex: index === activeIndex ? 7 : 1,
              backgroundColor: section.backgroundColor
            }}
            animate={{ flexGrow: index === activeIndex ? 7 : 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            onMouseEnter={() => setActiveIndex(index)}
          >
            <h3
              className={`year ${index === activeIndex ? "year-active" : ""}`}
              style={{ color: section.color,
               }}
            >
              {section.year}
            </h3>
            <motion.div
              className="video-container"
              style={{
                position: "relative",
                top: index === activeIndex ? "-20px" : "0",
                left: isMobile ? "0" : (index === activeIndex ? "0" : "calc(50% - 1%)"),  // Adjust left based on viewport width
                width: isMobile ? "100%" : "",  // Adjust width for mobile only
              }}
              transition={{ duration: 3, ease: "easeInOut" }}
            >
              <motion.video
                className={`video ${index === activeIndex ? "" : "video-small"}`}
                src={section.content}
                autoPlay
                muted
                loop
                style={{
                  position:'relative',
                  width: isMobile ? "90%" : (isTablet ? (index === activeIndex ? "100%" : "20%") : (index === activeIndex ? "58%" : "6%")),
                  height: isMobile ? (index === activeIndex ? "80%" : "10%")  : (isTablet ? (index === activeIndex ? "65%" : "90%") : (index === activeIndex ? "75%" : "90")), // Adjust top for mobile
  // Adjust width based on viewport width
                  objectFit: "cover",
                  transition: "all 1.5s ease-in-out",
                  transform: index === activeIndex ? "scaleY(1)" : "scaleY(0.8)",
                  left:isMobile ? "-5%" : (isTablet ? (index === activeIndex ? "0%" : "-50%") : (index === activeIndex ? "15%" : "calc(-75% + 4%)")),
                  top: isMobile ? (index === activeIndex ? "-20%" : "12%") : (isTablet ? (index === activeIndex ? "-25%" : "0%") : (index === activeIndex ? "-70%" : "0%")),
               // Adjust top for mobile
                }}
              />
            </motion.div>
            <h3 className={`title ${index === activeIndex ? "title-active" : ""}`} style={{ color: section.color }}>
            {index === activeIndex ? section.copy : section.title}
            </h3>
          </motion.div>
        ))}

      </div>
    );
};

export default Brand;
