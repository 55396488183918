import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RowRadioButtonsGroup = ({selectedValue, handleRadioChange}) => {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel value="General Inquiry" control={<Radio />} label="General Inquiry" />
        <FormControlLabel value="Air" control={<Radio />} label="Air" />
        <FormControlLabel value="Cruise" control={<Radio />} label="Cruise" />
        <FormControlLabel value="Indian Sub-Continent" control={<Radio />} label="Indian Sub-Continent" />
      </RadioGroup>
    </FormControl>
  );
};

export default RowRadioButtonsGroup;
