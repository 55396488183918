import React, { useEffect } from 'react';
import { useNavigate,redirect } from 'react-router-dom';
import './loadingscreen.css';
import { AnimatePresence, motion, useAnimation, useTransform } from 'framer-motion';
import { fadeAnimation, slideAnimation } from '../../config/motion';
import { useSnapshot } from 'valtio';
import { state } from '../../store/state';


const LoadingScreen = () => {
  const snap = useSnapshot(state);
  const navigate = useNavigate();


  const orangeFruitControl = useAnimation();
  const unitedTravelsText = useAnimation();
  const airplaneControl = useAnimation();
  const unitedTravelControl = useAnimation();


  useEffect(() => {
    const sequence = async () => {
      //white arrow
      await orangeFruitControl.start({ opacity:0.9, x: 545, y: -350, transition: { duration: 0.4} });
      // united travels text
      await unitedTravelsText.start({transition:{duration: 1, delay:0.5 }, opacity:1})
      const orangeFruitExit = orangeFruitControl.start({ opacity: 0, transition: { duration: 0.5 } });
      const airplaneEntry = airplaneControl.start({ x: 800, y: -1500, transition: { duration: 1, ease: "linear" }, opacity: 1 });
      await Promise.all([orangeFruitExit, airplaneEntry]);

      await unitedTravelsText.start({opacity:0})

    

      
      await unitedTravelControl.start({ scale: 50, transition: { duration: 1 } });
      state.setLoading(false);
    };

    if (snap.loading) {
      document.body.style.overflow = 'hidden';
      sequence();
    }if(snap.loading == false){
      document.body.style.overflow = 'auto';

        navigate('/about');
    }
    }
  , [snap.loading,navigate,orangeFruitControl, airplaneControl, unitedTravelsText, unitedTravelControl]);

  return (
    <AnimatePresence  className='root'>
    
      <div className="frame1">
        <motion.div className="unitedTravelU1"
          animate={unitedTravelControl}
        ></motion.div>
      </div>
      {snap.loading && (
        <>
          <motion.div
            initial={{ opacity:1, x: -98, y: 903 }}
            animate={orangeFruitControl}
            className="orangeFruitDoodlesFoodLogo1"
          ></motion.div>
           
          <motion.div
            initial={{opacity:0}}
            className="unitedTravelsFontLogo1"
            animate={unitedTravelsText}
          ></motion.div>
          <motion.div
            initial={{ opacity:0, x: 545, y: -609  }}
            animate={airplaneControl}
            className="airplane1"
          ></motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default LoadingScreen;
