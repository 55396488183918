import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import VectorImage from '../../images/TravelGPT_Vector.png';
import Vector1Image from '../../images/TravelGPT_Vector_1.png';
import OrangeFruitDoodlesFoImage from '../../images/TravelGPT_Orange_Fruit_Doodles_Food_Logo__2__2.png';
import Button1 from '../Buttons/Button1';
import Button2 from '../Buttons/Button2';
import Button3 from '../Buttons/Button3';
import Modal from './Modal';
import './TravelGpt.css';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';


const travelPrompts = [
  "I have 10 days. Can you suggest an itinerary for Columbia centered around surfing and local food?",
  "I have 7 days. Can you recommend a trip to Japan focused on culture and history?",
  "I have 5 days. Can you suggest a culinary tour in Italy?",
  "I have 14 days. Can you recommend an adventure trip to New Zealand?",
  "I have 10 days. Can you suggest a road trip in the United States?",
  "I have 8 days. Can you recommend a relaxing vacation in the Maldives?",
  "I have 12 days. Can you suggest a wildlife safari in Kenya?",
  "I have 9 days. Can you recommend a cultural exploration of India?",
  "I have 11 days. Can you suggest a hiking trip in Patagonia?",
  "I have 6 days. Can you recommend a city break in Europe?",
];

const getRandomTravelPrompt = () => {
  return travelPrompts[Math.floor(Math.random() * travelPrompts.length)];
};


function TravelGpt(props) {
  const [inputValue, setInputValue] = useState('');
  const [suggestion, setSuggestion ] = useState(getRandomTravelPrompt())
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [response, setResponse] = useState('');

  const handleApiCall = async (e) => {
    // Define the options for fetch
    e.preventDefault()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': " http://127.0.0.1:3000"},
        body: JSON.stringify({ prompt: inputValue })
    };
    const res = await fetch('https://openai-api-yjlw6dlh4q-uc.a.run.app/api/generate-text', requestOptions);
    const data = await res.json();

    // Set the response to state
    setResponse(data);
  }
  
  const handleButtonClick = (content) => {
    setShowModal(!showModal);
    setModalContent(content);

  };

  const handleTextClick = () => {
    setInputValue(suggestion);
    setIsTextVisible(false);
  };

const renderModalContent = () => {
  switch (modalContent) {
    case "content1":
      return <div>TravelGPT is intended to assist you in creating your dream vacation. You can use our sample prompt, or type in one of your own and TravelGPT will help you create an itinerary of activities and places to visit. <br></br> <br></br> For example try a prompt around your interests, like this “I have 7 days free in August. I am interested in cycling and history. Could you recommend a 7 day itinerary for Spain that allows me to cycle to historical sites?” It will give you a generated itinerary, once you have that you can ask additional prompts to help plan your trip extensively. </div>;
    case "content2":
      return <div>TravelGPT is easy to use, simply type in a travel inspired prompt or use one of our sample prompts to generated a potential travel itinerary for your next trip! <br></br> <br></br> Once TravelGPT has created a itinerary you can ask additional prompts to help improve the itinerary. You can set max distance travelled per day, what type of historical sites you wish to visit and even get specific restaurant recommendations!</div>;
    case "content3":
      return <div>Once TravelGPT has created your perfect itinerary, contact United Travels and let’s get your tickets booked to your dream vacation! Make sure to copy your itinerary for us. <br></br><br></br> Email: mary@unitedtravels.ca <br></br><br></br> Telephone: 902-422-0111</div>;
    default:
      return null;
  }
};


  return (
    <div className='TravelGpt1'>
    <head>
        <meta name="description" content="The best travel agency in atlantic canada for flights, tours and vacations with an AI itenerary builder"></meta>
        </head>
      <Button1 className="Button2" onClick={() => handleButtonClick("content1")}  />
      <Button2 className="Button3" onClick={() => handleButtonClick("content2")}  />
      <Button3 className="Button4" onClick={() => handleButtonClick("content3")}  />

      <AnimatePresence>
        {showModal && (
          <Modal onClose={handleButtonClick}>
           {renderModalContent()}
          </Modal>
        )}
      </AnimatePresence>
      <form className="Frame14">
      <input type="text" className="frame14Input" 
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
       
        <button className="Frame12" onClick={handleApiCall}>
          <div className="Frame">
            <img src={VectorImage} loading="lazy" alt={'Vector'} className="Vector" />
            <img src={Vector1Image} loading="lazy" alt={'Vector'} className="Vector1" />
          </div>
        </button>
      </form>
      {isTextVisible && (
      <div className="Frame17">
        <div className="Frame15">
        <div className="IHave10DaysCanYouSug" onClick={ (e) => handleTextClick(e)}>
        {suggestion}
          </div>
        </div>
      </div>
      )}
       
        {response &&  <div className="ResponseContainer"><div className='ResponseText'><Typewriter options={{autoStart:true, delay:50, strings:response}}/></div>         </div>
}

      <img
        src={OrangeFruitDoodlesFoImage}
        loading="lazy"
        alt={'Orange Fruit Doodles Food Logo (2) 2'}
        className="OrangeFruitDoodlesFo"
      />
      
    </div>
  );
}

export default TravelGpt;
