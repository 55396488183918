import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import Logo from '../../images/Logo.png'



const NavBar = () => {
  return (
    <div className='Container'>
    <img className='Logo' src={Logo}></img>
    <div className='NavContainer'>
     
    </div>
    </div>
  )
}

export default NavBar