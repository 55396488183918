import React from 'react';
import Bg1Image from '../../images/Ballons.png';
import Bg3Image from '../../images/Ballons.png';
import './statistics.css';

function Frame3(props) {
  return (
    <div className="property1Details">
      <div className="card">
        <div className="bg"></div>
        <div className="frame2">
          <img src={Bg1Image} loading="lazy" alt={'BG'} className="bg1" />
          <div className="q10kHappyCustomers">
            {`10K+ 
Happy
Customers`}
          </div>
        </div>
        <div className="line3"></div>
        <div className="line4"></div>
        <div className="q1">
          <div className="q30">{`30+`}</div>
          <div className="yearsInHalifax">{`years in Halifax`}</div>
        </div>
        <div className="q2">
          <div className="q60k">{`60K+`}</div>
          <div className="ticketsSold">{`Tickets sold`}</div>
        </div>
        <div className="q3">
          <div className="q1k">{`1k+`}</div>
          <div className="cruisesTaken">{`Cruises taken`}</div>
        </div>
        {false && <div className="bg2"></div>}
        {false && (
          <div className="frame21">
            <img src={Bg3Image} loading="lazy" alt={'BG'} className="bg3" />
            <div className="q10kHappyCustomers1">
              {`10K+ 
Happy
Customers`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Frame3;
