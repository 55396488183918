import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './menu.css';

const Menu = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    return (    

    <div >
      <div 
        className={`hamburger-menu ${isOpen ? "open" : ""}`} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu ${isOpen ? "open" : ""}`}>
        <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About</a></li>
          <li><a href='/TravelGPT'>TravelGPT</a></li>

          <li><a href='/contact'>Contact</a></li>
        </ul>
      </nav>
    </div>
                    
    );
}

export default Menu;
